import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import {Alert, CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import GeneralAccountInfoCreation from "./GeneralAccountInfoCreation";
import AccountCreationRolesPermission from "./AccountCreationRolesPermission";
import AccountCreationReview from "./AccountCreationReview";
import {AlertTitle} from "@mui/lab";
import AccountService from "../../services/accountService";
import {addAccount, setRoles} from "../../reducers/accountReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CreateAccount = (props) => {
    const dispatch = useDispatch();

    const [accountPermissions, setAccountPermissions] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(null);

    const [activeStep, setActiveStep] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [middleName, setMiddleName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [membershipType, setMembershipType] = React.useState('SINGLE');
    const [store, setStore] = React.useState(null);


    const [accountType, setAccountType] = React.useState('');

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const [didReceivesResponse, setDidReceivesResponse] = React.useState(false);
    const [didSave, setDidSave] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 5000);
    }

    const clearFields = () => {
        setFirstname('');
        setLastname('');
        setMiddleName('');
        setEmail('');
        setPhoneNumber('');
        setAccountPermissions([]);
        setSelectedRole(null);
    }

    const handleNext = () => {
        if (activeStep === 0){
            if (firstname === ''){
                showAlertMsg('First name field is required!', 'error');
                return;
            }

            if (store === null){
                showAlertMsg('Store is required!', 'error');
                return;
            }

            if (!membershipType){
                showAlertMsg('Membership type is required!', 'error');
                return;
            }

            if (lastname === ''){
                showAlertMsg('Last name field is required!', 'error');
                return;
            }

            if (email === ''){
                showAlertMsg('Email field is required!', 'error');
                return;
            }

            if (phoneNumber === ''){
                showAlertMsg('Member Number field is required!', 'error');
                return;
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 1){

            if (selectedRole === null && (accountPermissions === null || accountPermissions.length <= 0)){
                showAlertMsg('Must select a role or assign custom permissions to proceed!', 'error');
                return;
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (activeStep === 2){
            let ap = (accountPermissions !== null ? JSON.stringify(accountPermissions) : null);
            let sr = (selectedRole !== null ? JSON.stringify(selectedRole) : null);
            let tempType = null;
            console.log(selectedRole)
            if (selectedRole.roleID === 4) {
                tempType = 'USER';
            } else if (selectedRole.roleID === 2) {
                tempType = 'ADMIN';
            } else if (selectedRole.roleID === 2) {
                tempType = 'ADMIN';
            } else if (selectedRole.roleID === 1) {
                tempType = 'MASTER_ADMIN';
            }
            AccountService.createAccount(firstname, lastname, middleName, email, phoneNumber, null, null, accountType || tempType, ap, sr, membershipType, store?.storeID).then((response) =>{
                if (response.status === true){
                    dispatch(addAccount({
                        account: response.result
                    }))
                    setDidReceivesResponse(true);
                    setDidSave(true);
                } else {
                    setDidReceivesResponse(true);
                    setDidSave(false);
                }
            }).catch((err) => {
                console.log(err);
            });

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        clearFields();
        setDidReceivesResponse(false);
        setDidSave(false);
        setActiveStep(0);
    };

    const EndView = () => {
        return (
            (didReceivesResponse ?
                    (didSave ?
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                <strong>Account Created</strong> — account password is lastname + 22!.
                            </Alert>
                            :
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                <strong>Account Not Created </strong> — Unfortunately something went wrong in the backend
                                refresh the browser and try again. If you still are experiencing problems contact the IT department.
                            </Alert>
                    )
                    :
                    <CircularProgress color="primary" />
            )
        )
    };

    const steps = [
        {
            label: 'General Information',
            content: <GeneralAccountInfoCreation
                firstname={firstname} setFirstname={setFirstname}
                lastname={lastname} setLastname={setLastname}
                middleName={middleName} setMiddleName={setMiddleName}
                email={email} setEmail={setEmail}
                phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                membershipType={membershipType} setMembershipType={setMembershipType}
                store={store} setStore={setStore}
            />
        },
        {
            label: 'Roles & Permissions',
            content: <AccountCreationRolesPermission
                accountType={accountType} setAccountType={setAccountType}
                selectedRole={selectedRole} setSelectedRole={setSelectedRole}
                accountPermissions={accountPermissions} setAccountPermissions={setAccountPermissions}
            />
        },
        {
            label: 'Review',
            content: <AccountCreationReview
                firstname={firstname} lastname={lastname} middleName={middleName} email={email} phoneNumber={phoneNumber}
                selectedRole={selectedRole} accountPermissions={accountPermissions}
                membershipType={membershipType} store={store}
            />
        },
        {
            label: 'Create',
            content: <EndView />
        }
    ];

    React.useEffect(() => {
        AccountService.getRoles().then((response) =>{
            if (response.status === true){
                dispatch(setRoles({
                    roles: response.results
                }));
            } else {
                // show error could not get data
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <div>
            <Button size={"small"} variant="outlined" style={{minWidth:175}} onClick={handleClickOpen}>
                Create Account
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth='xl'
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Creating New Account
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {showAlert &&
                        <Alert severity={alertSeverity}>{alertMsg}</Alert>
                    }
                    <Box noValidate sx={{ mt: 3, mb: 3 }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => {
                                const stepProps = {};
                                const labelProps = {};

                                return (
                                    <Step key={step} {...stepProps}>
                                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                    {steps[activeStep].content}
                </DialogContent>
                <DialogActions>
                    {activeStep + 1 === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, mr: 2 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant={"contained"} onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button variant={"contained"} onClick={handleNext}>
                                    {activeStep + 1 === steps.length - 1 ? 'Create Account' : 'Next'}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default CreateAccount;
