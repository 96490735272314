import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from "react-redux";
import getText from "../../language/getText";
import Grid from "@mui/material/Grid";
import ChangeStoreValue from "./ChangeStoreValue";

const StoreGeneralInfo = (props) => {
    const languagePreference = useSelector(state => state.preferenceManager.languagePreference);

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return "Active";
            case 0:
                return "Inactive";
            default:
                return getText(languagePreference.lang, 'AM24');
        }
    }

    return (
        <div style={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <div className="d-inline-block" >
                        <div className="d-inline-block align-middle m-1">
                            <Typography variant="caption" display="block" gutterBottom>
                                Name
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.store.name}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeStoreValue type={'NAME'} store={props.store} />
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Address
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {props.store.address}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeAccountValue type={'MIDDLE_NAME'} account={props.account} />*/}
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Member Number
                            </Typography>

                            <Typography variant="h5" component="h2">
                                {props.store.phoneNumber}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            {/*<ChangeAccountValue type={'PHONE_NUMBER'} account={props.account} />*/}
                        </div>
                    </div>
                    <div className={"d-inline-block m-1"}>
                        <div className="d-inline-block align-middle">
                            <Typography variant="caption" display="block" gutterBottom>
                                Status
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {getStatusLabel(props.store.status)}
                            </Typography>
                        </div>
                        <div className="d-inline-block align-middle m-2">
                            <ChangeStoreValue type={'STATUS'} store={props.store} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default StoreGeneralInfo;
