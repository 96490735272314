import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from "../../components/Copyright/Copyright";
import background from "../../assets/background.jpg";
import {useNavigate} from "react-router-dom";
import AuthorizationService from "../../services/AuthorizationService";
import {Alert} from "@mui/material";


export default function SignUpView() {
    const navigate = useNavigate();

    const [isVerificationModalVisible, setIsVerificationModalVisible] = React.useState(false);
    const [verificationData, setVerificationData] = React.useState(null);
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordRep, setPasswordRep] = React.useState('');

    const [promotionPreference, setPromotionPreference] = React.useState(true);

    const [dob, setDob] =  React.useState(new Date());
    const [isMale, setIsMale] = React.useState(true);
    const [isFemale, setIsFemale] = React.useState(false);

    const [geoData, setGeoData] = React.useState(null);
    const [deviceInfo, setDeviceInfo] = React.useState(null);

    const [countryCode, setCountryCode] = React.useState('us');


    function clearInputs(){
        setFirstname('');
        setLastname('');
        setPhoneNumber('');
        setPassword('');
        setPasswordRep('');
        setDob(new Date());
    }

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertSeverity, setAlertSeverity] = React.useState('error');
    const [alertMsg, setAlertMsg] = React.useState('');

    const showAlertMsg = (message, severity) => {
        setShowAlert(true);
        setAlertSeverity(severity);
        setAlertMsg(message);

        setTimeout(() => {
            setShowAlert(false);
            setAlertMsg('');
        }, 6000);
    }

    const handleSubmit = (event) => {
        event.preventDefault();


        let canSignUp = false;

        if (firstname === ''){
            showAlertMsg('Must enter first name.', 'error');
            return;
        }

        if (lastname === ''){
            showAlertMsg('Must enter last name.', 'error');
            return;
        }

        if (phoneNumber === '' && email === ''){
            showAlertMsg('Must enter member number & email.', 'error');
            return;
        }

        if (phoneNumber === ''){
            showAlertMsg('Must enter member number.', 'error');
            return;
        }

        if (email === ''){
            showAlertMsg('Must enter email.', 'error');
            return;
        }

        if (password === ''){
            showAlertMsg('Must enter password.', 'error');
            return;
        }

        if (password.length < 8){
            showAlertMsg('Password must be at least 8 characters long.', 'error');
            return;
        }

        if (passwordRep !== password){
            showAlertMsg('Passwords entered do not match.', 'error');
            return;
        }

        if (phoneNumber.length <= 9) {
            showAlertMsg('Please enter a valid member number.', 'error');
        }

        if (dob.split('-').reverse().join('/') === new Date().toLocaleDateString()){
            alert('Please select a valid date of birth.');
            return;
        } else {
            if(dob >= new Date()) {
                alert('The date of birth you selected is in the future. Please try again.');
                return;
            } else {
                const today = new Date();
                const minAgeDate = new Date(today.getFullYear() - 21, today.getMonth(), today.getDate());
                if(new Date(dob.split('-').reverse().join('/')) >= minAgeDate) {
                    alert('You must be at least 21 years old to sign up.');
                    return;
                } else {
                    canSignUp = true;
                }
            }
        }

        if (canSignUp){
            AuthorizationService.register(firstname, lastname, phoneNumber, email, dob, isMale === true ? 'MALE' : isFemale === true ? 'FEMALE' : null, geoData, password, passwordRep, deviceInfo, promotionPreference).then(res => {
                if(res.status === true){
                    navigate('/login');
                } else {
                    showAlertMsg(res.message, 'error');
                    // setIsVerificationModalVisible(true);
                    // setVerificationData({
                    //     phoneNumber: phoneNumber,
                    //     status: false,
                    //     message: res.message
                    // });
                }
            });
        } else {

        }
    };

    const styles = {
        container: {
            flex: 1,
        },
        header: {
            flex: 1,
            justifyContent: 'flex-end',
            paddingHorizontal: 20,
            paddingBottom: 10
        },
        footer: {
            flex: 9,
            backgroundColor: 'transparent',
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            paddingHorizontal: 20,
            paddingVertical: 15
        },
        text_header: {
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 30
        },
        text_footer: {
            color: '#fff',
            fontSize: 18
        },
        action: {
            flexDirection: 'row',
            marginTop: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#f2f2f2',
            paddingBottom: 5
        },
        textInput: {
            flex: 1,
            marginTop: -12,
            paddingLeft: 10,
            color: '#fff',
        },
        button: {
            alignItems: 'center',
            marginTop: 10
        },
        signIn: {
            width: '100%',
            height: 50,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            borderWidth: 1,
            borderColor: '#fff'
        },
        textSign: {
            fontSize: 18,
            fontWeight: 'bold'
        },
        textPrivate: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 10
        },
        color_textPrivate: {
            color: '#444242'
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    height: '100%',
                    filter: 'blur(6px)', // Adjust the blur value as needed
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1, // Ensure the background is behind the content
                }}
            />
            <Box
                sx={{
                    // marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2, borderRadius: 6, borderColor: "transparent", backgroundColor: 'rgba(255,255,255,0.53)'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography style={{color: '#444242'}} component="h1" variant="h5">
                    Sign up
                </Typography>
                {showAlert &&
                    <Alert severity={alertSeverity}>{alertMsg}</Alert>
                }
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                value={firstname}
                                onChange={(val) => setFirstname(val.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                value={lastname}
                                onChange={(val) => setLastname(val.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Member Number"
                                name="phoneNumber"
                                autoComplete="tel"
                                onChange={(val) => {setPhoneNumber(val.target.value);}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                onChange={(val) => {setEmail(val.target.value);}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                required
                                fullWidth
                                id="bday"
                                label="Birth Date"
                                name="bday"
                                type="date"
                                autoComplete="bday"
                                value={dob}
                                onChange={(val) => { setDob(val.target.value); }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(val) => setPassword(val.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{backgroundColor: 'rgba(0,0,0,0.53)', borderRadius: 12 }}
                                required
                                fullWidth
                                name="rep-password"
                                label="Repeat Password"
                                type="password"
                                id="rep-password"
                                autoComplete="new-password"
                                value={passwordRep}
                                onChange={(val) => setPasswordRep(val.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel

                                control={<Checkbox checked={promotionPreference} onChange={() => { setPromotionPreference((prevState => !prevState)); }} color="primary" />}
                                label={<Typography style={{ color: '#000', fontSize: 12 }}>I agree to receive marketing messaging from RSBC at the member number provided above. I understand data rates may apply, reply STOP to opt out.</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={styles.textPrivate}>
                                <Typography style={styles.color_textPrivate}>By signing up you agree to our</Typography>
                                <Link style={{color: '#444242', marginLeft: 5, marginRight: 5}} onClick={() => { window.open("https://www.risingstormbrewing.com/j/privacy") }} variant="body2">
                                    {" "}Terms of Service
                                </Link>
                                <Typography style={styles.color_textPrivate}>{" "}and</Typography>
                                <Link style={{color: '#444242', marginLeft: 5, marginRight: 5}} onClick={() => { window.open("https://www.risingstormbrewing.com/j/privacy") }} variant="body2">
                                    {" "}Privacy policy
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link style={{ color: '#444242' }}  onClick={() => { navigate('/login') }} variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ }} />

        </Container>
    );
}

